.footer-cta-panel {
  background-color: $brand-primary;
  position: relative;
  overflow: hidden;

  .img-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 330px;
    z-index: 101;
    overflow: hidden;
    border-bottom: 28px solid $white;
    @include laptop {
      height: 100%;
      left: -200px;
      bottom: 0;
      width: 60%;
      border-bottom: 0;
      border-right: 30px solid $white;
      @include angled;
    }
    @include wide {
      width: 70%;
    }

    .bkg-img {
      background-image: url(../images/bkg-cta.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -160px;
      right: -160px;
      @include laptop {
        @include reverse-angle;
      }
    }

    .caption {
      @include laptop {
        transform: skew($angle);
        right: -15px;
      }
    }
  }

  .panel-content {
    padding: 330px 15px 0;
    text-align: center;
    @include laptop {
      padding: 0;
      width: 40%;
      margin-left: 54%;
    }
    @include wide {
      width: 31.5%;
      margin-left: 64%;
    }

    h1 {
      margin-bottom: 55px;
    }
    p {
      color: $white;
      margin: 0 auto 45px;
      max-width: 800px;

    }
  }
}



.footer-banner {
  overflow: hidden;
  background-color: $grey;
  position: relative;

  @include laptop {
    min-height: 35px;
  }

  &.service-alert-banner-active {
    // background-color: rgba($warning, 0.8);
    background-color: $warning;
    opacity: 0.8;
  }

  .service-alert-banner {
    background-color: transparent;
    padding: 10px 0;
    text-align: center;
    @include tablet {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: center;
    }
    @include laptop {
      justify-content: flex-start;
      padding: 4px 0;
    }

    span {
      display: block;
      margin: 0;
      @include tablet {
        margin-left: 15px;
      }
      @include laptop {
        &.more-info {
          margin-left: 25px;
        }
      }
    }

  }



  a.employee-login {
    display: block;
    background-color: #2a4c59;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px 0;
    @include laptop {
      @include angled;
      padding: 4px 50px 4px 0;
      position: absolute;
      right: -15px;
      top: 0;
      bottom: 0;
      width: 30%;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      @include laptop {
        @include reverse-angle;
        display: flex;
      }
    }

    .hide-lg {
      @include laptop {
        display: none;
      }
      @include desktop {
        display: inline;
      }
    }

    img {
      margin-right: 12px;
    }

  }
}


.footer-content {
  background-color: $dark-blue;
  padding: 70px 0 100px;
  text-align: center;
  @include tablet {
    padding: 60px 0 140px;
  }
  @include laptop {
    text-align: left;
    padding: 80px 0 140px;
  }

  .company-info {
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;
    @include tablet {
      text-align: left;
      align-items: center;
    }
    @include laptop {
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 0;
    }
    @include desktop {
      margin-top: -30px;
    }
    @include wide {
      margin-top: 30px;
    }
  }

  a.brand {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
    @include tablet {
      width: 160px;
      margin-right: 50px;
      margin-bottom: 0;
    }
    @include laptop {
      margin-bottom: 20px;
    }
    @include wide {
      margin-bottom: 0;
    }

    img {
      width: 160px;
    }
  }

  p {
    font-family: $serif;
    font-size: 16px;
    font-weight: 700;
    color: $white;
    line-height: 2.2;
    margin-bottom: 4px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      a {
        display: block;
      }
    }
  }

  ul.social {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    @include tablet {
      justify-content: flex-start;
    }
    li {
      margin-right: 35px;
      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      position: relative;
    }

    .fa-square {
      font-size: 25px;
      color: $brand-primary;

    }

    .fa-facebook {
      position: absolute;
      bottom: 3px;
      left: 8px;
      color: $white;
      font-size: 19px;
    }

    .fa-twitter {
      font-size: 17px;
      color: $white;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 40%;
      left: 50%;
    }

    .instagram {
      .fa-square {
        color: $white;
        font-size: 22px;
      }
      .fa-instagram {
        font-size: 25px;
        color: $brand-primary;
        position: absolute;
        left: -1px;
        top: -1px;
      }
    }
  }

  .nav-columns {
    @include tablet {
      column-gap: 30px;
      columns: 3;
    }
    @include desktop {
      column-gap: 30px;
      columns: 4;
    }
  }

  h3 {
    font-family: $serif;
    font-size: 18px;
    font-weight: 700;
    color: $grey;
    margin-bottom: 10px;
    pointer-events: none;
    cursor: default;
    text-align: center;
    @include desktop {
      text-align: left;
    }
  }

  ul.menu {

    > li {
      break-inside: avoid;
      padding-bottom: 20px;

      > a {
        font-family: $serif;
        font-size: 18px;
        font-weight: 700;
        color: $grey;
        margin-bottom: 3px;
        pointer-events: none;
        cursor: default;
      }

      &:nth-of-type(2) {
        @include desktop {
          padding-bottom: 30px;
        }
      }

      &:nth-last-of-type(2) {
        @include desktop {
          margin-top: 5px;
        }
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      .sub-menu {
        li {
          line-height: 1.7;
          a {
            font-family: $sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: $white;
            padding: 3px 0;
          }
        }
      }

    }
  }

  .emergency-contact {
    margin-top: 37px;
    @include tablet {
      position: absolute;
      bottom: -80px;
      left: 0;
      right: 0;
    }
    @include desktop {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      @include desktop {
        display: block;
      }
    }

    img {
      width: 27px;
    }

    .us-emergency {
      margin-right: 15px;
      @include desktop {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    a {
      display: flex;
      align-items: center;
      color: $white;
      font-family: $serif;
      font-size: 16px;
      font-weight: 700;
      span {
        margin-left: 14px;
        display: inline-block;
      }
    }

  }

  .subsidiary {
    position: absolute;
    bottom: -75px;
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-family: $sans-serif;
    font-weight: 400;
    left: 0;
    right: 0;
    line-height: 1.5;
    @include tablet {
      bottom: -115px;
    }

    a {
      color: $white;
    }
  }
}


.copyright {
  text-align: center;
  background-color: $white;
  padding: 7px 0;

  .copyright-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include laptop {
      justify-content: space-between;
    }

    div, a {
      font-family: $sans-serif;
      font-weight: 300;
      color: $black;
      font-size: 12px;
    }
  }
}
