// Boostrap
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px
);

// Slick
$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Theme Variables
$angle:                 30deg;

// Colors
$brand-primary:         #2d82a0;
$dark-blue:             #133947;
$white:                 #ffffff;
$grey:                  #bebfc1;
$black:                 #432312;
$warning:               #e9af19;
$warning-text:          #dd6319;

// Fonts
$sans-serif:            'Muli', sans-serif;
/* Extra light:         200 */
/* Light:               300 */
/* Regular:             400 */
/* Bold:                700 */

$serif:                 'Roboto Slab', serif;
/* Bold:                700 */


// Side-Nav
$sideNavWidth:          260px;
$sideNavDirection:      (right);

// Hamburger
$button_width:          40px;     // width of button area
$button_height:         60px;     // height of button area
$bar_thickness:         6px;     // thickness of button bars
$bar_roundness:         0;
$button_pad:            0px;      // left/right padding between button area and bars.
$button_bar_space:      8px;     // spacing between button bars
$button_duration:       0.3s;  // transition duration
$hamburger_normal:      $brand-primary;
$hamburger_active:      $brand-primary;


// Breakpoint variables
$width_mobile:          480px;
$width_phablet:         544px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;
$width_wide:            1440px;
$width_hd:              1640px;
$width_super_hd:         1840px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd       { @media (min-width: #{$width_hd}) { @content; } }
@mixin super_hd { @media (min-width: #{$width_super_hd}) { @content; } }


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
  z-index: 99;
}

@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}

// Flexbox mixin - prevents most IE 11 issues
@mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  max-width: #{$basis};
}


@mixin angled($angle: $angle) {
  transform: skew(-$angle);
}

@mixin reverse-angle($angle: $angle) {
  height: 100%;
  display: block;
  margin: 0 -5px; // reduces offset on skewed content
  transform: skew($angle);
}


@mixin reverse() {
  transform: skew($angle);
}

@mixin border-right {
  position: relative;
  margin-right: 18px;
  border-right: 10px solid $brand-primary;

  &:after {
    display: block;
    content: '';
    border-right: 10px solid $brand-primary;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -28px;
  }
}
