.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    overflow-y: auto;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $white;
    transition: all 0.3s ease;
    border-left: 10px solid $brand-primary;
    @include tablet {
      width: $sideNavWidth + 80px;
      #{$sideNavDirection}: -$sideNavWidth - 80px;
    }
    @include laptop {
      width: $sideNavWidth + 100px;
      #{$sideNavDirection}: -$sideNavWidth - 100px;
    }

    .language-selector {

      .widget_wpglobus .list {
        display: flex;
        a {
          display: inline-block;
          @include flex(0, 0, 50%);
          background-color: $grey;
          padding: 5px 15px 6px;
          font-size: 16px;
          text-transform: uppercase;
          font-family: $serif;
          font-weight: 700;
          color: $white;
          cursor: pointer;
          text-align: center;

          &.wpglobus-current-language {
            background-color: $brand-primary;
            color: $white;
            cursor: default;
          }

          span.name {
            display: none;
          }

          span.code {
            &:before, &:after {
              content: none;
            }
          }
        }
      }
    }

    h3 {
      text-align: center;
      background-color: lighten($brand-primary, 15);
      margin: 0;
      padding: 10px;
      color: $white;
      border-bottom: 3px solid $brand-primary;
    }

    .nav > li {
      border-bottom: 3px solid $brand-primary;
      > a {
          display: block;
          color: $brand-primary;
          font-size: 16px;
          padding: 15px 20px;

          @include laptop {
            font-size: 18px;
          }
      }

      &.phone-primary {
        background-color: $brand-primary;
        a {
          color: $white;
          font-weight: 700;
        }
      }


      &.phone-us, &.phone-ca {
        background-color: lighten($brand-primary, 15);
        text-align: center;
        a {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: $white;

          &:before {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            margin-right: 8px;
            height: 20px;
            width: 27px;
            content: '';
          }
        }
      }

    }



    .phone-us a::before {
      background-image: url(../images/flag-american.png);
    }

    .phone-ca a::before {
      background-image: url(../images/flag-canadian.png);
    }

    .dropdown-menu {
      position: relative;
      box-shadow: none;
      float: none;
      border-radius: 0;
      border: none;
      padding: 0 10px 15px;
      margin-top: -5px;

      li {
        a {
          padding: 5px 20px;
          position: relative;
        }
      }
    }

    .dropdown-toggle:after {
      right: 20px;
      top: 24px;
      position: absolute;
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
        @include desktop {
          #{$sideNavDirection}: -$sideNavWidth - 100px;
        }
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
    @include tablet {
      #{$sideNavDirection}: $sideNavWidth + 80px;
    }
    @include laptop {
      #{$sideNavDirection}: $sideNavWidth + 100px;
    }
    @include desktop {
      #{$sideNavDirection}: 0;
    }
}
