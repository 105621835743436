* {
  outline: 0!important;
}

body, html {
  font-size: 16px;
  font-family: $sans-serif;
  @include smooth-fonts();
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

/* Flexbox Sticky Footer (ie11+ crossbrowser) */
html {
  display: flex;
}

body.flex {
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}

.wrap {
  flex: 1 1 auto;
  overflow-x: hidden;
}
/* End Flexbox Sticky Footer */

.row.centered-row {
  justify-content: center;
}

h1,.h1, h2,.h2, h3,.h3,
h4,.h4, h5,.h5, h6.h6,
p, li {
  color: $black;
}

h1,.h1 {
  font-family: $serif;
  font-weight: 700;
  font-size: 36px;
  margin: 0 0 15px;
  @include tablet {
    font-size: 38px;
  }
  @include laptop {
    font-size: 42px;
  }
  @include desktop {
    font-size: 45px;
  }

  &.section-header {
    position: relative;
    text-align: center;
    padding-bottom: 40px;
    margin: 0 0 80px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 5px;
    }

    &.dark {
      color: $dark-blue;

      &:after {
        background-image: url(../images/header-border-dark.png);
      }
    }

    &.light {
      color: $brand-primary;

      &:after {
        background-image: url(../images/header-border-light.png);
      }
    }

    &.white {
      color: $white;

      &:after {
        background-image: url(../images/header-border-white.png);
      }
    }
  }
}

h2,.h2 {
  font-family: $sans-serif;;
  font-weight: 400;
  font-size: 20px;
  @include tablet {
    font-size: 22px;
  }
  @include desktop {
    font-size: 25px;
  }
}

h3,.h3 {
  font-family: $serif;
  font-weight: 700;
  font-size: 18px;
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
}

a {
  transition: 0.3s ease-in-out;
  &:hover, &:focus {
    text-decoration: none;
  }
}

strong {
  font-weight: bold;
}

.nav {
  li {
    a {
      display: block;
    }
  }
}

.container-fluid {
  max-width: 100%;
  @include tablet {
    width: 740px;
  }
  @include laptop {
    width: 980px;
  }
  @include desktop {
    width: 1170px;
  }
  @include wide {
    width: 1380px;
  }
  @include hd {
    width: 1580px;
  }
  @include super_hd {
    width: 1750px;
  }
}

.container-large {
  @extend .container;
  @include wide {
    width: 1390px;
  }
}

.angled {
  @include angled;

  &.border-right {
    position: relative;
    margin-right: 18px;
    border-right: 10px solid $brand-primary;

    &:after {
      display: block;
      content: '';
      border-right: 10px solid $brand-primary;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -28px;
    }
  }

}

.reverse-angle {
  @include reverse-angle;
}

.caption {
  width: 205px;
  // background-color: rgba($white, 0.8);
  background-color: $white;
  opacity: 0.8;
  position: absolute;
  pointer-events: none;
  z-index: 200;
  right: 0;
  bottom: 30px;
  font-size: 12px;
  text-align: left;
  padding: 1px 8px;

  &.reverse {
    transform: skew($angle);
    right: -15px;
  }
}

.panel {
  overflow: hidden;
  border-bottom: 28px solid $white;
  padding: 80px 0;
}


.slick-slider {

  .slick-arrow {

    &:before {
      transition: 0.3s ease-in-out;
    }

    &.slick-prev {
      &:before {
        content: url(../images/arrow-left.png);
      }
    }

    &.slick-next {
      &:before {
        content: url(../images/arrow-right.png);
      }
    }
  }


  .slick-dots {
    left: 0;

    li, button {
      height: 10px;
      width: 17px;
    }

    li button {
      opacity: 0.5;
      background-color: $white;
      @extend .angled;
      &:before {
        content: none;
      }
    }

    li.slick-active button {
      opacity: 1;
    }

  }

}


.home, .page-template-template-landing{
  .service-alert-banner {
    // background-color: rgba($warning, 0.8);
    background-color: $warning;
    opacity: 0.8;
  }
}

.service-alert-banner {
  display: block;
  background-color: lighten($warning, 10);

 .reverse-angle {
   display: inline-flex;
   justify-content: flex-start;
   align-items: center;
 }

 a {
   &:hover, &:focus {
     color: darken($warning-text, 15);
   }
 }

  strong {
    font-size: 15px;
    @include phablet {
      font-size: 16px;
    }
    @include tablet {
      font-size: 18px;
    }
  }

  .service-message {
    margin-left: 15px;
    font-size: 14px;
    @include tablet {
      font-size: 16px;
    }
  }

  .more-info {
    font-size: 14px;
    margin-left: 10px;
    @include tablet {
      margin-left: 25px;
      font-size: 16px;
    }
  }

  span, strong {
    display: inline-block;
    text-transform: uppercase;
    color: $warning-text;
    font-family: $sans-serif;
  }
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
