.hero {
  position: relative;
  padding: 120px 0 80px;
  background-image: url(../images/hero-home.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  text-align: center;
  @include tablet {
    padding: 150px 0 100px;
  }
  @include laptop {
    padding: 200px 0 160px;
    text-align: left;
  }

  .cta-box {
    position: relative;
    padding: 20px;
    z-index: 0;
    @include tablet {
      padding: 40px;
      display: inline-block;
    }
    @include laptop {
      padding-left: 0;
    }

    &:before, &:after {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      right: 100%;
      background-color: $white;
      opacity: 0.95;
      content: '';
      z-index: -1;
    }
    &:before {
        left: 0%;
        width: 100%;
    }
    &:after {
        left: -300%;
    }
  }

  .cta-content {
    max-width: 100%;
    @include tablet {
      width: 560px;
    }
  }

  .h1 {
    color: $brand-primary;
    margin: 0;
  }

  .h2 {
    margin: 20px 0 35px;
  }
}


.strapline {
  border-top: 14px solid $brand-primary;
  border-bottom: 14px solid $brand-primary;
  text-align: center;

  .angled {
    padding: 32px 0;
    margin: 0 20px;
    border-width: 0;
    @include tablet {
      border-width: 18px;
      padding: 32px 75px;
    }

    &:after {
      content: none;
      border-width: 18px;
      right: -50px;
      @include tablet {
        content: '';
      }
    }
  }

  p {
    font-size: 20px;
    font-weight: 200;
    line-height: 1.2;
    @include tablet {
      font-size: 22px;
    }
  }
}

.freight-panel {
  border-top: 28px solid $white;
  overflow: hidden;
  text-align: center;
  position: relative;
  background-image: url(../images/bkg-map.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: fit-content;

  @include laptop {
    height: 740px;
  }

  .map-row {
    @include laptop {
      flex-wrap: nowrap;
    }
  }

  .map-card {
    background-color: $dark-blue;
    opacity: 0.8;
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
    @include tablet {
      height: 200px;
      text-align: left;
    }
    @include laptop {
      height: 174px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    h2 {
      color: $white;
      margin: 0 0 25px;
      font-size: 18px;
      @include laptop {
        font-size: 20px;
      }
      @include desktop {
        font-size: 25px;
      }
    }

    p {
      color: $white;
      margin-bottom: 0;
    }
  }

  .content-left, .content-right {
    @include flex(0, 0, 100%);
    padding: 0 15px;
    @include tablet {
      @include flex(0, 0, 50%);
    }
    @include laptop {
      flex-grow: 1;
      flex-basis: 0;
      padding: 0;
    }
  }

  .content-left {
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 0;
    }

    .map-card {
      &:after {
        position: absolute;
        left: 100%;
        top: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid rgba($dark-blue, 0.8);
        @include laptop {
          content: '';
        }
      }
    }
  }

  .content-right {
    .map-card {
      &:before {
        position: absolute;
        right: 100%;
        bottom: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid rgba($dark-blue, 0.8);
        @include laptop {
          content: '';
        }
      }
    }
  }

  .map-data {
    @include flex(0, 0, 220px);
    position: relative;

    .map-points {
      position: relative;
      width: 200px;
      margin: 0 auto;
    }

    .point {
      transform: rotate(45deg);
      width: 30px;
      height: 30px;
      border: 7px solid $brand-primary;
      background-color: $white;
      position: absolute;

    }

    .point-1 {
      top: 5px;
      left: 54px;
    }

    .point-2{
      top: 139px;
      left: 122px;
    }

    .point-3 {
      top: 199px;
      left: 62px;
    }

    .point-4 {
      top: 333px;
      left: 95px;
    }

  }
}

.french {
    .freight-panel {
      .map-card {
        padding: 15px;

        h2 {
          color: $white;
          margin: 0 0 10px;
          font-size: 18px;
          @include laptop {
            font-size: 20px;
          }
          @include desktop {
            font-size: 22px;
          }
        }

        p {
          font-size: 16px;
        }
      }
    }
}

.rail-services-panel {
  background-image: url(../images/bkg-metal.jpg);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .img-panel {
    background-image: linear-gradient(rgba($dark-blue,0.2),rgba($dark-blue,0.2)),
      url(../images/rail-services-train.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    width: 100%;
    @include laptop {
      background-image: url(../images/rail-services-train.jpg);
      background-position: 75%;
      height: 100%;
      width: 30%;
      bottom: 0;
    }
    @include wide {
      background-position: center;
    }
  }

  .panel-content {
    @include laptop {
      width: 70%;
      margin-left: 30%;
    }
  }

  .services {
    padding: 0 15px;
    @include laptop {
      padding: 0;
      width: 77%;
    }
    @include desktop {
      width: 72%;
    }
    @include wide {
      width: 70%;
    }
    @include hd {
      max-width: 1085px;
      width: 72%;
    }
  }


  .service-row {
    background-color: $white;
    position: relative;
    margin-bottom: 30px;
    padding: 30px 15px;
    @include tablet {
      height: 158px;
      display: flex;
      align-items: center;
      padding: 30px;
    }
    @include laptop {
      padding: 30px 0 30px 30px;
    }

    &.row-1::after {
      @include wide {
        width: 385px;
      }
      @include hd {
        width: 400px;
      }
    }

    &.row-2::after {
      @include wide {
        width: 285px;
      }
      @include hd {
        width: 300px;
      }
    }

    &.row-3 {
      margin-bottom: 0;
    }

    .row {
      margin-top: auto;
      margin-bottom: auto;
      min-width: 100%;
    }

    &:after {
      position: absolute;
      background-color: $white;
      @extend .angled;
      top: 0;
      bottom: 0;
      left: calc(95% - 100px);
      width: 200px;
      z-index: 100;
      @include laptop {
        content: '';
      }
      @include wide {
        width: 185px;
      }
      @include hd {
        width: 200px;
      }
    }

    .title-col, .content-col {
      position: relative;
      z-index: 200;
      padding: 0 15px;
    }

    .title-col {
      @include flex(0, 0, 100%);
      text-align: center;
      margin-bottom: 20px;
      @include tablet {
        margin-bottom: 0;
        @include flex(0, 0, 40%);
      }

      a {
        display: block;
      }
    }

    .content-col {
      @include flex(0, 0, 100%);
      @include tablet {
        @include flex(0, 0, 60%);
      }

      p {
        margin: 0;
        text-align: center;
        @include tablet {
          text-align: left;
        }
      }
    }

  }
}



.gallery-panel {
  border-bottom: 28px solid $white;
  overflow: hidden;

  .photo-col {
    @include flex(0, 0, 100%);
    &.col-1, &.col-2, &.col-3 {
      border-bottom: 28px solid $white;
    }
    @include tablet {
      @include flex(0, 0, 50%);
      @include angled;

      &.col-1, &.col-3 {
        border-right: 30px solid $white;
      }

      &.col-3 {
        border-bottom: none;
      }
    }
    @include wide {
      @include flex(0, 0, 23%);

      &.col-1, &.col-2 {
        border-bottom: none;
      }

      &.col-2 {
        border-right: 30px solid $white;
      }

      &.col-1, &.col-4 {
        @include flex(0, 0, 27%);
      }
    }


    &.col-4 .gallery-image {
      background-image: url(../images/gallery-photo-4.jpg);
    }

  }

}


.brand-panel {
  background-color: $dark-blue;
  text-align: center;

  h1.section-header {
    margin-bottom: 25px;
  }

  .sub-heading {
    color: $white;
    font-size: 18px;
  }

  .associations-carousel {
    margin-bottom: 120px;
    @include tablet {
      margin-bottom: 0;
    }
  }

  .logo-carousel {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    height: 160px;
    display: flex;
    align-items: center;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
    }

    .slick-prev {
      left: 0;
      @include laptop {
        left: -25px;
      }
    }

    .slick-next {
      right: 0;
      @include laptop {
        right: -25px;
      }
    }

    img {
      max-height: 125px;
    }
  }
}
