.card {
  border-radius: 0;
  border: none;

  h1 {
    font-size: 32px;
    margin: 0 0 6px;
    color: $dark-blue;
    @include tablet {
      font-size: 36px;
    }
    @include laptop {
      font-size: 38px;
    }
    @include desktop {
      font-size: 40px;
    }
  }

  p.post-date {
    margin-bottom: 20px;
    color: darken($grey, 15);
  }
}

.post-row {
  margin-bottom: -30px;
}

.post-preview  {
  margin-bottom: 30px;
  h2 {
    margin: 0 0 5px;
  }

  p {
    margin-bottom: 0;
  }

  .btn {
    margin-top: 20px;
  }

  a {
    text-decoration: none!important;
  }
}

.single {
  .featured-image {
    margin-bottom: 20px;
  }
  .card-block {
    padding: 20px;
    @include tablet {
      padding: 30px;
    }
  }

  .card {
    .btn-container {
      padding: 0 20px 20px;
      @include tablet {
        padding: 0;
      }
    }

    &.post {
      margin-bottom: 30px;
    }
  }
}

.management-profile {
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  p.job-title {
    font-size: 20px;
    font-weight: 400;
    color: rgba($dark-blue, 0.8);
    margin-bottom: 15px;
  }

  .profile-img {
    text-align: center;
  }
}


nav.pagination {
  justify-content: center;
  margin-bottom: 30px;
  @include laptop {
    justify-content: flex-start;
  }
  .nav-links {

    span, a {
      font-size: 20px;
      font-weight: 700;
      font-family: $serif;
      margin: 0 6px;
    }
    span.current {
      color: $grey;
    }
    a {
      color: $white;
    }
  }
}
