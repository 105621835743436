.hero {
  &-landing {
    .h1 {
      margin-bottom: 50px;
    }
  }

  &-tank-car {
    position: relative;
    padding: 250px 0 0;

    @include laptop {
      padding: 400px 0 0;
    }

    .cta-container {
      display: flex;
      // justify-content: flex-start;
    //   justify-content: flex-end;
    }

    .cta-box {
      padding-left: 20px;
      text-align: center;
      box-shadow: 1px 2px 3px rgba($white, .5);

      @include tablet {
        padding-left: 40px;
        // padding-right: 0;
      }
      //
      // &::before,
      // &::after {
      //   opacity: 0.6;
      // }

      // &::after {
      //   right: -300%;
      //   left: 100%;
      // }
    }

    p.h1 {
      margin-bottom: 20px;
    }

    p.h2 {
      margin-bottom: 0;
      font-family: $serif;
      font-weight: 700;
    }

    // &::after {
    //   content: '';
    //   background: linear-gradient(0, rgba($white, 1) 0%, rgba($white, 0) 30%);
    //
    //   @include stretch();
    //
    //   @include desktop {
    //     background: linear-gradient(45deg, rgba($white, 1) 0%, rgba($white, 0) 30%);
    //   }
    // }

    .container-large,
    .caption {
      z-index: 500;
    }
  }
}

.strapline-tank-car {
  border-top: 10px solid $white;
  background-color: $brand-primary;
  text-align: center;
  padding: 30px 0;

  h2,
  p {
    color: $white;
    font-family: $serif;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;

    @include tablet {
      font-size: 36px;
    }

    @include laptop {
      font-size: 48px;
    }
  }

  p {
    font-style: italic;
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 18px;

    @include tablet {
      font-size: 22px;
    }

    @include laptop {
      font-size: 24px;
    }
  }
}


.page-header {
  background-color: $dark-blue;
  border-bottom: 28px solid $white;
  padding: 100px 0 80px;

  h1 {
    position: relative;
    margin-bottom: 0;
    z-index: 200;
  }
}

.page-title h1 {
  margin: 0 0 60px;
}


.map-panel {
  border-bottom: 28px solid $white;
  border-top: 3px solid $brand-primary;
  position: relative;

  &.has-overlay {
    &:after {
      @include stretch;
      background-color: $white;
      opacity: 0.3;
      // background-color: rgba($white, 0.3);
      content: '';
    }
  }

  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 300;
  }
}


.page-content-panel {
  position: relative;

  a {
    text-decoration: underline;
    font-weight: bold;
  }

  h3 {
    font-size: 22px;
    margin: 30px 0 20px;
    color: $dark-blue;
  }

  h2 {
    font-family: $serif;
    font-size: 28px;
    font-weight: 400;
    color: $dark-blue;
    margin: 20px 0;
  }

  p, li {
    font-size: 18px;
    line-height: 1.35;
    font-weight: 300;
  }

  ul:not(.gform_fields):not(.gfield_checkbox):not(.gfield_radio) {
    list-style-type: none;
    padding-left: 10px;
    @include tablet {
      padding-left: 45px;
    }

    li {
      padding-left: 45px;
      position: relative;
      margin-bottom: 8px;

      &:before {
        content: '';
        @include angled;
        width: 20px;
        height: 11px;
        background-color: $brand-primary;
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
      }

      ul {
        margin: 15px 0 20px;
        padding-left: 0;
      }
    }
  }

  &.blue-panel {
    background-color: $dark-blue;

    h1.section-header {
      @extend .white;
    }

    .page-content {
      p, li, a, h2, h3 {
        color: $white;
      }

      ul:not(.gform_fields):not(.gfield_checkbox):not(.gfield_radio) li:before {
        background-color: $white;
      }

      p, li {
        font-weight: 600;
      }
    }

    .marketing-contact {
      h3, p {
        color: $white;
      }
    }

    .document-card {
      h2 {
        margin: 0 0 8px;
        color: $brand-primary;
      }

      p {
        color: $black;
      }
    }
  }
}

.marketing-contact {
  margin-top: 45px;

  p {
    margin-bottom: 0;
  }
}

.contact-content {
  h3:first-of-type {
    margin-top: 0;
  }
}

.landing-page-panel {
  &.no-img .row {
    justify-content: center;
  }

  &.img-1 {
    @include laptop {
      min-height: 600px;
    }
  }

  &.img-2 {
    @include laptop {
      min-height: 970px;
    }

    .page-img-panel {
      height: 710px;
    }

    .img-panel-1 {
      bottom: 370px;
    }
  }
}

.page-img-panel {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  height: 340px;
  right: -210px;
  width: 55%;

  @include angled;

  @include wide {
    width: 45%;
  }

  .img-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: -100px;
    height: 340px;
  }

  .angled-img {
    @include reverse-angle;
    @include stretch;

    left: -150px;
    right: -150px;
    background-image: url(../images/gallery-photo-4.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .caption {
    @include reverse;

    right: auto;
    left: -15px;
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
  }

  .img-panel-1 {
    width: 690px;
  }

  .img-panel-2 {
    width: 900px;
  }
}

.tank-car-panel {
  &.img-2 {
    @include laptop {
      min-height: 680px;
    }
  }

  &.img-3 {
    @include laptop {
      min-height: 970px;
    }
  }

  h3 {
    color: $brand-primary;
  }

  .tank-car-content {
    h3 {
      margin-top: 15px;
      font-weight: 700;
      font-size: 24px;

      @include tablet {
        font-size: 28px;
      }

      @include laptop {
        font-size: 32px;
      }
    }
  }

  .divider-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 20px;

    &::before,
    &::after {
      content: '';
      height: 1px;
      background-color: $black;

      @include flex(1, 0, auto);
    }

    h3 {
      margin: 0 15px;
    }
  }

  .page-img-panel {
    @include reverse-angle(15deg);

    top: 10px;
    bottom: auto;
    display: none;

    @include laptop {
      display: block;
    }

    .angled-img {
      @include angled(15deg);

      left: -80px;
    }

    .img-panel {
      height: 300px;
      bottom: auto;

      &-1 {
        top: 0;
        width: 500px;

        @include wide {
          width: 600px;
        }
      }

      &-2 {
        top: 310px;
        width: 400px;

        @include wide {
          width: 500px;
        }
      }

      &-3 {
        top: 620px;
        width: 300px;

        @include wide {
          width: 400px;
        }
      }
    }
  }
}

.blue-border {
  height: 14px;
  background-color: $brand-primary;
}

.warehouse-panel {
  display: flex;
  border-bottom: 28px solid $white;
  overflow: hidden;
  flex-wrap: wrap;
  @include laptop {
    flex-wrap: nowrap;
  }

  .warehouse-content {
    @include flex(0, 0, 100%);
    background-color: $dark-blue;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    border-bottom: 28px solid $white;
    padding: 60px 0;
    @include laptop {
      margin-left: -200px;
      @include angled;
      @include flex(0, 0, 750px);
      border-right: 30px solid $white;
      border-bottom: 0;
      padding: 0;
    }
    @include desktop {
      @include flex(0, 0, 950px);
    }

    p, h3, a {
      color: $white;
    }

    p {
      font-size: 18px;
      font-weight: 600;
    }

    h3 {
      font-size: 22px;
    }


    .content {
      background-color: $dark-blue;
      margin: 0 auto;
      padding: 0 15px;
      @include phablet {
        width: 540px;
        max-width: 100%;
      }
      @include tablet {
        width: 720px;
        max-width: 100%;
      }
      @include laptop {
        padding: 45px 115px 45px 45px;
        @include reverse-angle;
        @include stretch;
        width: 100%;
        left: 200px;
        right: -150px;
      }
      @include desktop {
        padding-right: 185px;
      }

    }
  }


  .warehouse-map {
    @include flex(0, 0, 100%);
    @include laptop {
      @include flex(0, 0, calc(100% - 400px));
      margin-left: -150px;
    }
    @include desktop {
      @include flex(0, 0, calc(100% - 600px));
    }
  }

  .location-details {
    margin-top: 40px;
    @include laptop {
      margin-top: 0;
    }
    @include desktop {
      margin-top: 40px;
    }
  }
}


.gallery-page-panel {
  overflow: hidden;

  .photo-col {
    border-bottom: 28px solid $white;
    @include flex(0, 0, 100%);
    &.col-1, &.col-2, &.col-3 {
      border-bottom: 28px solid $white;
    }
    @include tablet {
      @include flex(0, 0, 50%);
      @include angled;

      &:nth-of-type(odd) {
        border-right: 30px solid $white;
        .zoom-icon {
          left: calc(50% + 150px);
        }
      }

      &:nth-of-type(even) {
        .zoom-icon {
          left: 50%;
        }
        .caption {
          right: 40px;
        }
      }

    }
    @include wide {
      @include flex(0, 0, 23%);

      &:nth-of-type(even) .caption {
        right: -15px;
      }

      &.col-4 .caption {
        right: 40px;
      }

      &.col-2, &.col-6 {
        border-right: 30px solid $white;
      }

      &:nth-of-type(odd) .zoom-icon {
        left: calc(50% + 70px);
      }

      &:nth-of-type(even) .zoom-icon {
        left: calc(50% + 70px);
      }

      &.col-1, &.col-5 {
        .zoom-icon {
          left: calc(50% + 120px);
        }
      }

      &.col-1, &.col-4, &.col-5, &.link-col {
        @include flex(0, 0, 27%);


      }
    }

    .zoom-icon {
      position: absolute;
      left: 0;
      right: 0;
      font-size: 28px;
      color: transparent;
      top: 48%;
      transition: 0.4s ease-in-out;
      pointer-events: none;
      z-index: 100;

      @include tablet {
        @include reverse-angle;

        transform: translate(-50%, -50%);
        left: calc(50% + 70px);
        right: auto;
      }
    }

    &:hover, &:focus {
      .gallery-image:before {
        background-color: $dark-blue;
        opacity: 0.7;
        cursor: pointer;
      }

      .zoom-icon {
        color: $white;
        cursor: pointer;
      }
    }

    &.link-col .link-text .h1 {
      font-size: 36px;
    }

  }

}

.gallery-row {
  @include tablet {
    margin: 0 -120px;
  }
}

.photo-col {
  display: block;
  overflow: hidden;
  height: 400px;
  position: relative;
  text-align: center;

  @include tablet {
    @include angled;
  }

  &.link-col {
    &:hover, &:focus {
      .gallery-image:before {
        background-color: $brand-primary;
      }
    }

    .gallery-image:before {
      background-color: $brand-primary;
      opacity: 0.9;
    }

    .link-text {
      position: absolute;
      display: block;
      z-index: 200;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      right: 0;

      @include tablet {
        transform: translate(-50%, -50%);
        left: calc(50% - 60px);
        right: auto;
        top: 51%;
      }

      .fa {
        color: $white;
        font-size: 40px;
        margin-bottom: 30px;
      }

      h1, .h1 {
        color: $white;
      }

      span {
        color: $white;
        font-size: 25px;
      }
    }
  }

  .caption {
    @include tablet {
      @include reverse;
      right: -15px;
    }
  }
}


.gallery-image {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;

  @include tablet {
    @include reverse-angle;

    left: -110px;
    right: -110px;
  }

  &:before {
    content: '';
    @include stretch();

    background-color: $dark-blue;
    opacity: 0.1;
    transition: 0.4s ease-in-out;
  }

  img {
    display: none;
  }
}


.hidden-image {
  display: none;
}


.employee-downloads {
  .employee-downloads-section{
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 25px;

    h3 {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 15px;

      &:after {
        top: calc(100% + 10px);
      }
    }
  }

  .sdm_download_item {
    display: flex;
    align-content: center;
    padding: 0 0 10px;
    margin: 0 0 10px;
    border: transparent;
    border-bottom: solid 1px #ececec;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    flex-direction: column;

    @include phablet {
      flex-direction: row;
      justify-content: space-between;
    }

    > div:not(.sdm_download_item_top, .sdm_download_link) {
      display: none;
    }

    .sdm_download_title {
      font-size: 16px;
      line-height: 1.3;
      font-weight: 300;
      margin-bottom: 10px;

      @include phablet {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

    .sdm_download_item_count {
      display: none;
    }
  }

  .employee-login {
    #loginform {
      p {
        label {
          display: block;
        }

        input[type="text"],
        input[type="password"] {
          padding: 10px;
          width: 100%;
          border: 1px solid #ccc;
        }
      }
    }
  }

  .employee-login p.login-submit,
  span.sdm_download_button {
    position: relative;

    input#wp-submit,
    a.sdm_download.blue {
      min-width: 0;
      @extend .btn;
      @extend .btn-primary;
      font-weight: 700;
      font-size: 16px;
      border-radius: 0;
      padding: 15px 20px;
      transition: 0.5s ease-in-out;
      line-height: 1.2;
      border: none;
      text-align: center;
      cursor: pointer;
      background: $brand-primary;
      position: relative;
      z-index: 2;
      color: $white !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    &:hover,
    &:focus {
      input#wp-submit,
      a.sdm_download.blue {
        color: $white !important;
      }
    }
  }
}
