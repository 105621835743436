header.banner {
  position: relative;
  z-index: 1000;

  .brand {
    display: block;
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 1001;
    width: 140px;
    @include tablet {
      left: 0;
      width: 160px;
    }
    @include desktop {
      width: 150px;
    }
    @include wide {
      width: 160px;
    }
    @include hd {
      width: auto;
    }
  }

  .nav-bar {
    background-color: $white;
    border-bottom: 10px solid $brand-primary;

    a {
      text-transform: uppercase;
      font-size: 14px;
      color: $brand-primary;
      transition: 0.3s ease-in-out;
      font-family: $serif;
      font-weight: 700;
      @include wide {
        font-size: 16px;
      }
      @include hd {
        font-size: 17px;
      }
      @include super_hd {
        font-size: 18px;
      }
    }
  }

  .nav-bar-content {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
  }

  .nav {
    padding: 10px 0;
    justify-content: flex-end;

    >  li {
      position: relative;

      &.menu-item-has-children {
        > a.dropdown-toggle:after {
          font-family: FontAwesome;
          content: '\f103';
          display: inline-block;
          border-left: none;
          border-top: none;
          border-right: none;
          margin-left: 2px;
          width: auto;
          height: auto;
          vertical-align: baseline;
          @include wide {
            margin-left: 4px;
          }
          @include hd {
            margin-left: 7px;
          }
        }
      }

      > a {
        padding: 14px 6px;
        transition: color 0.3s ease-in-out;
        @include wide {
          padding: 14px 10px;
        }
        @include super_hd {
          padding: 14px 16px;
        }

        &:hover, &:focus {
          color: $dark-blue;
        }
      }

    }

    .dropdown-menu {
      transition: opacity 0.5s ease-in-out;
      background-color: $brand-primary;
      list-style-type: none;
      padding: 15px;
      border: none;
      border-radius: 0;
      box-shadow: none;

      li {
        white-space: nowrap;
        a {
          transition: color 0.3s ease-in-out;
          font-size: 14px;
          font-family: $sans-serif;
          text-transform: none;
          padding: 4px 0;
          color: rgba($white, 0.7);
          @include wide {
            font-size: 16px;
          }

          &:hover, &:focus {
            color: $white;
          }
        }
      }
    }
  }

  .nav-primary {
    padding-right: 50px;
    margin-right: 50px;
    @include tablet {
      margin-right: 70px;
    }
    @include desktop {
      @include border-right;
      padding-right: 15px;
      margin-right: 0;
    }
    @include wide {
      padding-right: 25px;
    }
    @include hd {
      padding-right: 50px;
    }
  }

  .hamburger {
    margin-right: 20px;
    @include tablet {
      margin-right: 10px;
    }
  }

  .emergency-info {
    padding: 10px 0 10px 25px;
    text-align: center;
    @include wide {
      padding-left: 35px;
    }
    @include hd {
      padding-left: 50px;
    }
    h3 {
      text-transform: uppercase;
      color: $brand-primary;
      font-size: 16px;
      @include wide {
        font-size: 18px;
      }
    }
  }

  .emergency-numbers {
    display: flex;
    justify-content: space-between;
    img {
      height: 19px;
      width: 30px;
    }

    a {
      display: flex;
      align-items: center;
      margin: 0 5px;
      @include wide {
        margin: 0 10px;
      }
      @include hd {
        margin: 0 20px;
      }
      span {
        margin-left: 10px;
        display: inline-block;
      }
    }
  }


  .banner-bottom {
    // background-color: rgba($white, 0.6);
    background-color: $white;
    opacity: 0.6;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }

  .banner-content {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include laptop {
      justify-content: space-between;
    }
  }

  .service-alert-banner {
    margin-right: -25px;
    padding: 5px 30px;
    @include tablet {
      margin-right: 0;
    }
    @include laptop {
      padding-left: 105px;
      margin-left: 55px;
    }
    @include desktop {
      margin-left: 0;
      padding-left: 165px;
    }
    @include hd {
      padding-left: 210px;
    }

    .more-info {
      @include tablet {
        display: none;
      }
      @include laptop {
        display: inline-block;
      }
    }
  }


  .language-selector {
    margin-top: -1px;
    display: none;
    width: 100%;
    @include laptop {
      display: block;
    }

    .widget_wpglobus .list a {
      @include angled;
      display: inline-block;
      float: right;
      background-color: $brand-primary;
      padding: 5px 15px 6px;
      font-size: 16px;
      text-transform: uppercase;
      font-family: $serif;
      font-weight: 700;
      color: rgba($white, 0.3);
      cursor: pointer;
      @include tablet {
        font-size: 18px;
      }

      &:first-of-type {
        margin-right: 8px;
      }

      &.wpglobus-current-language {
        color: $white;
        cursor: default;
      }

      span.name {
        display: none;
      }

      span.code {
        @include reverse-angle;
        &:before, &:after {
          content: none;
        }
      }
    }
  }
}


body.french {
    header.banner {
      .nav-bar a {
          font-size: 12px;
          @include wide {
            font-size: 14px;
          }
      }

      .nav > li > a {
        padding: 14px 4px;
        transition: color 0.3s ease-in-out;
        @include wide {
          padding: 14px 6px;
        }
        @include super_hd {
          padding: 14px 12px;
        }
      }
    }
}
