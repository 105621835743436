// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper  {
  margin-top: 15px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.gform_fields  {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }
  }

  .gfield {
    padding: 0 15px;
    @include flex(0, 0, 100%);

    &.half-col {
      @include flex(0, 0, 100%);
      @include tablet {
        @include flex(0, 0, 50%);
      }
    }
  }

  .gsection {
    margin-top: 30px;
    h2 {
      border-bottom: 1px solid $white;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  .gform_body {
    input[type=text],
    input[type=tel],
    input[type=email],
    textarea {
      width: 100%;
      padding: 8px 10px;
      background-color: $white;
      font-weight: 300;
      color: $black;
      border: none;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba($black, 0.8);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba($black, 0.8);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba($black, 0.8);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba($black, 0.8);
      }
    }


    textarea {
      height: 160px;
    }

    .ginput_container_date .clear-multi {
      display: flex;
      input {
        display: block;
        margin-right: 10px;
      }

      label {
        @extend small;
        margin-bottom: 0;
      }

      .gfield_date_month, .gfield_date_day {
        input {
          width: 50px;
        }
      }

      .gfield_date_year input {
        width: 80px;
      }
    }

    .gfield_radio, .gfield_checkbox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: -10px;

      li {
        display: flex;
        align-items: baseline;
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-of-type {
          margin-right: 0;
        }

        label {
          margin-bottom: 0;
          margin-left: 6px;
        }
      }
    }

    .ginput_complex {
      display: flex;
      margin: 0 -15px;
      flex-wrap: wrap;
      input {
        max-width: 100%;
      }
      span {
        display: inline-block;
        padding: 0 15px;
        margin-bottom: 10px;
        @include flex(0, 0, 100%);
        @include tablet {
          @include flex(0, 0, 50%);
        }

        label {
          @extend small;
          margin-bottom: 0;
        }
      }
    }

    .gf_clear {
      display: none;
    }

    ul li {
      margin-bottom: 15px;


      label {
        font-weight: 700;
      }

      &.recaptcha label {
        display: none;
      }
    }
  }

  input[type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    @extend .btn;
    @extend .btn-white;
    cursor: pointer;
  }
}

.landing-page-panel {
  .gform_wrapper {
    width: 500px;
    max-width: 100%;
    .gform_body {
      input[type=text],
      input[type=tel],
      input[type=email],
      textarea {
        background-color: $grey;
      }
    }
    input[type=submit] {
      @extend .btn-primary;
    }
  }
}

.gform_confirmation_message {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid $brand-primary;
    border-radius: 0;
    background-color: $white;
    border-color: $brand-primary;
    color: $brand-primary;
}
