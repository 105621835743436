.page-content-panel {

  .widget.card {
    margin-bottom: 30px;
    h3 {
      margin-top: 0;
    }

    ul:not(.gform_fields):not(.gfield_checkbox):not(.gfield_radio) {
      padding-left: 10px;

      li {
        padding-left: 30px;
        font-weight: 400;
        font-size: 16px;

        &:before {
          width: 15px;
          height: 9px;
        }
      }
    }

    a {
      text-decoration: none!important;
    }
  }

}
